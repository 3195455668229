// @import "normalize";
// @import "htmlize";
@import "./src-container.scss";

$sans: Helvetica, sans-serif;
$serif: Georgia, serif;
$monospace: Lucida Console, monospace;
$code-size: 80%;
$smaller: 90%;
$max-width: 710px;

body {
  width: 95%;
  margin: 2% auto;
  font-size: 14px;
  line-height: 1.4em;
  font-family: $serif;
  color: #333;
}

@media screen and (min-width: 600px) {
  body {
    font-size: 18px;
  }
}

@media screen and (min-width: 910px) {
  body {
    width: 900px;
  }
}

::selection {
  background: #d6edff;
}

p {
  margin: 1em auto;
}

ol,
ul {
  margin: 0 auto;
}

dl {
  margin: 0 auto;
}

.title {
  text-align: center;
  margin: 0.8em auto;
  color: black;
}

.subtitle {
  text-align: center;
  font-size: 1.1em;
  line-height: 1.4;
  font-weight: bold;
  margin: 1em auto;
}

.abstract {
  margin: auto;
  width: 80%;
  font-style: italic;
}

.abstract p:last-of-type:before {
  content: "    ";
  white-space: pre;
}

.status {
  font-size: $smaller;
  margin: 2em auto;
}

[class^="section-number-"] {
  margin-right: 0.5em;
}

[id^="orgheadline"] {
  clear: both;
}

#footnotes {
  font-size: $smaller;
}

.footpara {
  display: inline;
  margin: 0.2em auto;
}

.footdef {
  margin-bottom: 1em;
}

.footdef sup {
  padding-right: 0.5em;
}

.content a {
  color: #527d9a;
  text-decoration: underline dotted;
}

.content a:hover {
  color: #003355;
}

figure {
  padding: 0px;
  margin: 1em auto;
  text-align: center;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

.MathJax_Display {
  margin: 0 !important;
  width: $smaller !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #a5573e;
  line-height: 1em;
  font-family: $sans;
}

h1,
h2,
h3 {
  line-height: 1.4em;
}

h4,
h5,
h6 {
  font-size: 1em;
}

@media screen and (min-width: 600px) {
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.3em;
  }
  h1,
  h2,
  h3 {
    line-height: 1.4em;
  }
  h4,
  h5,
  h6 {
    font-size: 1.1em;
  }
}

dt {
  font-weight: bold;
}

/* table */

table {
  margin: 1em auto;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-collapse: collapse;
}

thead {
  border-bottom: 2px solid;
}

table td + td,
table th + th {
  border-left: 1px solid gray;
}

table tr {
  border-top: 1px solid lightgray;
}

td,
th {
  padding: 0.3em 0.6em;
  vertical-align: middle;
}

caption.t-above {
  caption-side: top;
}

caption.t-bottom {
  caption-side: bottom;
}

caption {
  margin-bottom: 0.3em;
}

figcaption {
  margin-top: 0.3em;
}

th.org-right {
  text-align: center;
}

th.org-left {
  text-align: center;
}

th.org-center {
  text-align: center;
}

td.org-right {
  text-align: right;
}

td.org-left {
  text-align: left;
}

td.org-center {
  text-align: center;
}

blockquote {
  margin: 1em 2em;
  padding-left: 1em;
  border-left: 3px solid #ccc;
  overflow-wrap: break-word;
}

kbd {
  background-color: #f7f7f7;
  font-size: $code-size;
  margin: 0 0.1em;
  padding: 0.1em 0.6em;
}

.todo {
  background-color: red;
  color: white;
  padding: 0.1em 0.3em;
  border-radius: 3px;
  background-clip: padding-box;
  font-size: $code-size;
  font-family: $monospace;
  line-height: 1;
}

.done {
  background-color: green;
  color: white;
  padding: 0.1em 0.3em;
  border-radius: 3px;
  background-clip: padding-box;
  font-size: $code-size;
  font-family: $monospace;
  line-height: 1;
}

.priority {
  color: orange;
  font-family: $monospace;
}

/* Because tag span is set to float.  This is more like a hacking.  Maybe we
   need a cleaner solution. */
#table-of-contents li {
  clear: both;
}

.tag {
  font-family: $monospace;
  font-size: 0.7em;
  font-weight: normal;
}

.tag span {
  padding: 0.3em 0.3em;
  float: right;
  margin-right: 0.5em;
  border: 1px solid #bbb;
  border-radius: 3px;
  background-clip: padding-box;
  color: #333;
  background-color: #eee;
  line-height: 1;
}

.timestamp {
  color: #bebebe;
  font-size: $smaller;
}

.timestamp-kwd {
  color: #5f9ea0;
}

.org-right {
  margin-left: auto;
  margin-right: 0px;
  text-align: right;
}

.org-left {
  margin-left: 0px;
  margin-right: auto;
  text-align: left;
}

.org-center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.underline {
  text-decoration: underline;
}

#postamble p,
#preamble p {
  font-size: $smaller;
  margin: 0.2em;
}

p.verse {
  margin-left: 3%;
}

.inlinetask {
  background: #ffffcc;
  border: 2px solid gray;
  margin: 10px;
  padding: 10px;
}

#org-div-home-and-up {
  font-size: 70%;
  text-align: right;
  white-space: nowrap;
}

.linenr {
  font-size: $smaller;
}

.code-highlighted {
  background-color: #ffff00;
}

#bibliography {
  font-size: $smaller;
}

#bibliography table {
  width: 100%;
}

.creator {
  display: block;
}

@media screen and (min-width: 600px) {
  .creator {
    display: inline;
    float: right;
  }
}
