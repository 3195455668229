@import "../node_modules/@ideabile/style-org-themes/style.scss";
@import "./playlist.scss";
//@import url("https://gongzhitaao.org/orgcss/org.css");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,600;1,400;1,600&display=swap");

body {
  font-display: block;
  font-family: "EB Garamond", serif;
  background: #fcfcf9;

  line-height: 1.25em;
  font-size: 3em;

  @media (min-device-width: 40em) and (orientation: landscape) {
    font-size: 1.25em;
  }
}

.content {
  max-width: 62.5e m;
}

pre {
  overflow-x: scroll;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "EB Garamond", serif;
  color: #36ade2;
}

nav {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: 80em) {
    flex-wrap: nowrap;
  }
}

nav h1 {
  font-size: 1.2em;
  line-height: 1em;
}

nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

  li {
    margin: 0.5rem 2rem;
  }

  @media (min-width: 80em) {
    justify-content: flex-end;
    flex-wrap: nowrap;

    li {
      margin: 0 2rem;
    }
  }
}

#content > div:not(nav) {
  padding: 2em 0;
}

footer {
  text-align: center;
  font-size: 0.624em;
  line-height: 1.2;
}
