.playlist {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .playlist-player {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 0 2em;

    img,
    iframe {
      max-width: 20em;
      width: 100%;
    }

    iframe {
      height: 80px;
    }
  }

  ul {
    list-style: none;
    max-width: 20em;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 0.625em;
      display: flex;
      align-items: center;
      img {
        margin-right: 0.625em;
      }
      span {
        display: block;
      }

      .album {
        display: none;
      }

      .artist {
        font-size: 0.625em;
      }
    }
  }
}
